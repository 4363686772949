import React from 'react';
import './styles/Servicos.css';

const Servicos = () => (
        <div class="servicos" id="services">
                <h2>Serviços</h2>
                <div class="card1">
                    <a href="/"><h1>Criação de Sites</h1></a>
                    <h3>Landing Pages</h3>
                    <h3>Páginas Informativas</h3>
                    <h3>Design Responsivo</h3>
                    <h3>E-Commerce</h3>
                </div>
                <div class="card2">
                    <a href="/"><h1>Gestão de Tráfego Pago</h1></a>
                    <h3>Campanhas de Anúncios</h3>
                    <h3>Google ADS</h3>
                    <h3>Meta ADS</h3>
                    <h3>Socialmedia</h3>
                </div>
                <div class="card3">
                    <a href="/"><h1>Design Gráfico</h1></a>
                    <h3>Criação de Logotipo</h3>
                    <h3>Identidade Visual</h3>
                    <h3>Banners e Panfletos</h3>
                    <h3>Marketing Stories</h3>
                </div>
                
                <div class="item1">
                    <a href="https://wa.me/send?phone=5519997013275&text=Ol%C3%A1,%20quero%20um%20or%C3%A7amento!"><button id="clique2">Quero fazer um orçamento!</button></a>
                </div>
        </div>
);

export default Servicos;
